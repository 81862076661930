import React, { useEffect } from "react";
// import { Switch } from '@headlessui/react';
import CreatorController from "../../../../services/creator"
import { toast } from "react-hot-toast";
import { Button, Checkbox, Input, Option, Select, Switch } from "@material-tailwind/react";
import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";

export default function OthersSettings() {
  // const {} = React.useContext()
  const [creator, setCreator] = React.useState<any>(null)
  const [loading, setLoading] = React.useState(true)
  const [spamFilterText, setSpamFilterText] = React.useState(creator?.spammedTxt || '')
  const [openDntVoice, setOpenDntVoice] = React.useState(creator?.activeVoice || false)
  const [voice, setVoice] = React.useState(creator?.voiceAudio || 1)
  const [dntAction, setDntAction] = React.useState<string>(creator?.actionTxt || '')
  const [textAnimation, setTextAnimation] = React.useState<string>(creator?.txtAnimation || '')
  const [textShowAnimation, setTextShowAnimation] = React.useState<string>(creator?.txtShowAnimation || '')
  const [textHideAnimation, setTextHideAnimation] = React.useState<string>()
  const [color, setColor] = React.useState<string>(creator?.txtColor || '')

  const getCreator = async () => {
    const response = await CreatorController.getCreatorOfUser()
    if (response instanceof Error) {
      return
    }

    setCreator(response.data?.result)

    setLoading(false)
  }

  const genToken = async () => {
    const response = await CreatorController.genDntToken()
    if (response instanceof Error) {
      return
    }

    if (!creator?.dntToken) {
      toast('Tạo token thành công', {
        icon: '👏',
      })
    }

    else {
      toast('Khoá token thành công', {
        icon: '👏',
      })
    }

    setCreator(response?.result)

  }

  const updateOthersSettings = async () => {
    const request = {
      spammedText: spamFilterText,
      dntVoice: voice,
      actionTxt: dntAction,
      txtAnimation: textAnimation,
      txtShowAnimation: textShowAnimation,
      txtHideAnimation: textHideAnimation,
      txtColor: color,
      voiceAudio: voice,
      activeVoice: openDntVoice
    }

    CreatorController.updateOthersSettings(request).then(() => {
      getCreator()
      toast('Cập nhật thành công', {
        duration: 3000,
      })
    })
  }

  React.useEffect(() => {
    getCreator()
  }, [])

  React.useEffect(() => {
    if (!creator)
      return;

    setSpamFilterText(creator?.spammedText || '')
    setOpenDntVoice(creator?.activeVoice || false)
    setVoice(creator?.voiceAudio || 1)
    setDntAction(creator?.actionTxt || '')
    setTextAnimation(creator?.txtAnimation || '')
    setTextShowAnimation(creator?.txtShowAnimation || '')
    console.log(creator)
    setTextHideAnimation(creator?.txtHideAnimation)
    setColor(creator?.txtColor || '')

  }, [creator])


  return (
    <React.Fragment>
      {
        loading ? <>Loading...</> : <div className="grid grid-cols-12 gap-4">
          <div className="col-span-6 uppercase">
            tạo token donate
          </div>
          <div className="col-span-6">
            <Switch
              checked={creator?.dntToken !== null}
              onChange={(checked) => {
                genToken()
              }}
              size={10}
              label='Tạo'
            />
            {/* <span className="sr-only">Enable notifications</span>
            <span
              className={`${creator?.dntToken ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            /> */}
            <div className="border rounded shadow-md p-2">
              {creator?.dntToken ? creator?.dntToken : 'Token donate của bạn chưa có'}
              {/* <input type="text" className="w-full p-2 mt-2" placeholder="Token donate" disabled /> */}
            </div>
          </div>

          <div className="col-span-6 uppercase">
            LỌC TỪ KHOÁ TRÁNH SPAM DONATE
          </div>
          <div className="col-span-6">
            <textarea className="w-full p-4 resize-none" placeholder="Thêm nhiều từ khoá bằng cách xuống dòng" value={spamFilterText} onChange={(e) => {
              setSpamFilterText(e.target.value)
            }}></textarea>
          </div>

          <div className="col-span-6 uppercase">
            Bảng tổng sắp
          </div>
          <div className="col-span-6">
            <select className="w-full p-2"></select>
          </div>

          <div className="col-span-6 uppercase">
            Giọng chị Google
          </div>
          <div className="col-span-6">
            <div className="mb-2">
              {/* <Checkbox value={true} /> Bật */}
              <Switch
                checked={openDntVoice}

                onChange={(checked) => {
                  // genToken()
                  // console.log(checked)
                  setOpenDntVoice(value => !value)
                }}
                size={10}
                label="Bật"
                id='open-google'
              // className="mb-2"
              />

            </div>
            {
              openDntVoice && (
                <Select label="Chọn giọng nói" value={voice} onChange={(e) => {
                  setVoice(e)
                  // console.log(e)
                }}>
                  <Option value={1}>Giọng Nữ Miền Nam</Option>
                  <Option value={2}>Giọng Nữ Miền Bắc</Option>
                  <Option value={3}>Giọng Nam Miền Nam</Option>
                  <Option value={4}>Giọng Nam Miền Bắc</Option>
                </Select>)
            }

          </div>

          <div className="col-span-6 uppercase">
            Văn bản hiển thị
          </div>
          <div className="col-span-6 flex justify-between gap-2">
            <span className="w-36 py-2">Người dùng </span>
            {/* <select className="w-full p-2"></select>
             */}
            <Input size={'md'} variant="outlined" label="Đã donate" value={dntAction} onChange={(e) => {
              setDntAction(e.target.value)
            }} />
            <span className="w-36 py-2">10.000 VNĐ</span>
          </div>

          <div className="col-span-6 uppercase">
            Màu văn bản
          </div>
          <div className="col-span-6">
            <Input type="color" className="w-36 p-0" label="Màu văn bản" value={color} onChange={(e) => {
              setColor(e.target.value)
            }} />
            {/* <select className="w-full p-2"></select> */}
          </div>

          <div className="col-span-6 uppercase">
            Hiệu ứng chữ
          </div>
          <div className="col-span-6">
            <Select label="Hiệu ứng chữ" value={textAnimation} onChange={(e) => {
              setTextAnimation(e)
            }}>
              <Option value="bounce">bounce</Option>
              <Option value="flash">flash</Option>
              <Option value="pulse">pulse</Option>
              <Option value="rubberBand">rubberBand</Option>
              <Option value="shake">shake</Option>
              <Option value="swing">swing</Option>
              <Option value="tada">tada</Option>
              <Option value="wobble">wobble</Option>
            </Select>
            {/* <select className="w-full p-2"></select> */}
          </div>

          <div className="col-span-6 uppercase">
            Hiệu ứng xuất hiện
          </div>
          <div className="col-span-6">
            {/* <select className="w-full p-2"></select> */}
            <Select label="Hiệu ứng xuât hiện" value={textShowAnimation} onChange={(e) => {
              setTextShowAnimation(e as string)
            }}>
              {/* <optgroup label="Bouncing Entrances">
              </optgroup> */}
              <Option value="bounceIn">bounceIn</Option>
              <Option value="bounceInDown">bounceInDown</Option>
              <Option value="bounceInLeft">bounceInLeft</Option>
              {/* <optgroup label="Fading Entrances"></optgroup> */}
              <Option value="fadeIn">fadeIn</Option>
              <Option value="fadeInDown">fadeInDown</Option>
              <Option value="fadeInDownBig">fadeInDownBig</Option>
              <Option value="fadeInLeft">fadeInLeft</Option>
              <Option value="fadeInLeftBig">fadeInLeftBig</Option>
              <Option value="fadeInUp">fadeInUp</Option>
              {/* <optgroup label="Flippers"></optgroup> */}
              <Option value="flipInX">flipInX</Option>
              <Option value="flipInY">flipInY</Option>
              {/* <optgroup label="Rotating Entrances"></optgroup> */}
              <Option value="rotateIn">rotateIn</Option>
              <Option value="rotateInDownLeft">rotateInDownLeft</Option>
              <Option value="rotateInDownRight">rotateInDownRight</Option>
              <Option value="rotateInUpLeft">rotateInUpLeft</Option>
              {/* <optgroup label="Sliding Entrances"></optgroup> */}
              <Option value="slideInUp">slideInUp</Option>
              <Option value="slideInDown">slideInDown</Option>
              <Option value="slideInLeft">slideInLeft</Option>
              {/* <optgroup label="Zoom Entrances"></optgroup> */}
              <Option value="zoomIn">zoomIn</Option>
              <Option value="zoomInDown">zoomInDown</Option>
              <Option value="zoomInLeft">zoomInLeft</Option>
              <Option value="zoomInRight">zoomInRight</Option>
              <Option value="zoomInUp">zoomInUp</Option>
              {/* <optgroup label="Specials"></optgroup> */}
              <Option value="rollIn">rollIn</Option></Select>
          </div>

          <div className="col-span-6 uppercase">
            Hiệu ứng biến mất
          </div>
          <div className="col-span-6">
            <Select label="Hiệu ứng biến mất" value={textHideAnimation} onChange={(e) => {
              setTextHideAnimation(e as string)
            }}>
              {/* <optgroup label="bouncing"></optgroup> */}
              <Option value="bounceOut">bounceOut</Option>
              <Option value="bounceOutLeft">bounceOutLeft</Option>
              <Option value="bounceOutUp">bounceOutUp</Option>
              {/* <optgroup label="fading"></optgroup> */}
              <Option value="fadeOut">fadeOut</Option>
              <Option value="fadeOutDown">fadeOutDown</Option>
              <Option value="fadeOutLeft">fadeOutLeft</Option>
              <Option value="fadeOutLeftBig">fadeOutLeftBig</Option>
              <Option value="fadeOutUp">fadeOutUp</Option>
              <Option value="fadeOutUpBig">fadeOutUpBig</Option>
              {/* <optgroup label="flippers"></optgroup> */}
              <Option value="flipOutX">flipOutX</Option>
              <Option value="flipOutY">flipOutY</Option>
              {/* <optgroup label="rotating"></optgroup> */}
              <Option value="rotateOut">rotateOut</Option>
              <Option value="rotateOutDownLeft">rotateOutDownLeft</Option>
              <Option value="rotateOutDownRight">rotateOutDownRight</Option>
              <Option value="rotateOutUpLeft">rotateOutUpLeft</Option>
              <Option value="rotateOutUpRight">rotateOutUpRight</Option>
              {/* <optgroup label="sliding"></optgroup> */}
              <Option value="slideOutUp">slideOutUp</Option>
              <Option value="slideOutDown">slideOutDown</Option>
              <Option value="slideOutLeft">slideOutLeft</Option>
              {/* <optgroup label="zoom"></optgroup> */}
              <Option value="zoomOut">zoomOut</Option>
              <Option value="zoomOutDown">zoomOutDown</Option>
              <Option value="zoomOutLeft">zoomOutLeft</Option>
              <Option value="zoomOutUp">zoomOutUp</Option>
            </Select>
          </div>

          <div className="col-span-6 uppercase">
            Link donate của bạn
            <div className="border rounded-md bg-gray-200 p-4 lowercase">https://dev-all.myepis.cloud/creator/{creator?.slug}</div>
          </div>
          <div className="col-span-6 pt-10">
            <a href="#" className="bg-red-500 py-4 px-6 border rounded text-white">Mở</a>
          </div>

          <div className="col-span-6 ">
            <span className="uppercase">Link hiển thị donate</span>
            <div className="border rounded-md bg-gray-200 p-4">https://broken-smoke-b4aa.episcloud.workers.dev/{creator?.dntToken}</div>
          </div>
          <div className="col-span-6 pt-10">
            <a href="#" className="bg-red-500 py-4 px-6 border rounded text-white">Đổi link</a>
            <a href="#" className="bg-red-500 py-4 px-6 border rounded text-white" onClick={() => {
              CreatorController.getVirtualDonate(creator?.dntToken)
            }}>Donate ảo</a>
            <a href="#" className="bg-red-500 py-4 px-6 border rounded text-white">Mở</a>
          </div>

          <div className="col-span-6 uppercase">
            Link hiển thị bảng xếp hạng donate
            <div className="border rounded-md bg-gray-200 p-4 lowercase">https://broken-smoke-b4aa.episcloud.workers.dev/{creator?.dntToken}</div>
          </div>
          <div className="col-span-6 pt-10">
            <a href="#" className="bg-red-500 py-4 px-6 border rounded text-white">Đổi link</a>
            <a href="#" className="bg-red-500 py-4 px-6 border rounded text-white">Donate ảo</a>
            <a href="#" className="bg-red-500 py-4 px-6 border rounded text-white">Mở</a>
          </div>

          <div className="col-span-3">
            <Button onClick={() => {
              updateOthersSettings();
            }} variant="outlined" className="flex items-center gap-3">
              Lưu
              <ArrowUpCircleIcon strokeWidth={2} className="h-6 w-6" />
            </Button>
          </div>

        </div>
      }

    </React.Fragment>
  )
}