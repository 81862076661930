import { CloudArrowUpIcon, PlayIcon, PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  IconButton,
  Input
} from "@material-tailwind/react";
import React from "react";


import CreatorController from "../../../../services/creator";
import { toast } from "react-hot-toast";

function getFileSize(file: any) {
  let _size = file.size;

  let fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
    i = 0;
  //check if file is in GB,MB,KB or Bytes
  while (_size > 900) {
    _size /= 1024; //divide file size
    i++;
  }
  //get exact size
  let exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];

  return exactSize;
}

export default function AudioSettings() {
  const [showModal, setShowModal] = React.useState(false);
  const [uploadModal, setShowUploadModal] = React.useState(false);
  const [audioLink, setAudioLink] = React.useState(`https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3`);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);

  const uploadAudio = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile || '');

    const response = await CreatorController.uploadResources(formData)
    if (response instanceof Error) {
      return
    }

    toast('Tải lên thành công', {
      icon: '👏',
    })

    setAudioLink(response?.data?.result?.link)
    setFileName(response?.data?.result?.name)
    setSelectedFile(null)
    setShowUploadModal(false)
  }

  return (
    <React.Fragment>
      <div className="grid grid-cols-4 gap-2">
        <div>
          <div className="rounded border shadow-lg p-5 flex items-center gap-4 justify-center">
            <Button variant="text" className="flex items-center gap-3 p-2" color="green" onClick={() => {
              setShowUploadModal(true)
            }}>
              <PlusCircleIcon height={25} fontSize={18} />

              <div>Thêm âm thanh</div>
            </Button>

          </div>
        </div>

        <div>
          <div className="rounded border shadow-lg p-5 text-center">
            <IconButton onClick={() => {
              setShowModal(true)
            }}>
              <PlayIcon color='white' height={20} />
            </IconButton>
            <span className="ml-2">
              Âm thanh 1</span>

          </div>
        </div>
      </div>

      <Dialog open={showModal} size="xs" handler={() => {
        setShowModal(false)
      }}>
        <DialogBody divider>
          <div className="flex justify-center">
            <figure>
              <figcaption>Listen to the T-Rex:</figcaption>
              <audio
                className="w-[250px]"
                controls
                src={audioLink}>
                <a href={audioLink}>
                  Download audio
                </a>
              </audio>
            </figure>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => {
              setShowModal(false)
            }}
            className="mr-1"
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>

      <Dialog open={uploadModal} size="xs" handler={() => {
        setShowUploadModal(false)
      }}>
        <DialogBody divider>
          {
            !selectedFile && <div className="flex items-center justify-center w-full">
              <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click để thực hiện thêm file</span></p>
                  {/* <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p> */}
                </div>
                <input id="dropzone-file" type="file" className="hidden" accept="audio/*" onChange={(e) => {
                  setSelectedFile(e?.target?.files[0])
                  console.log(e?.target?.files[0])
                }} />
              </label>
            </div>
          }

          {
            selectedFile && <React.Fragment> <div className="p-4 bg-gray-300 flex justify-between">
              <div className="py-1">
                <span className="text-black">{selectedFile?.name}</span> <span>{getFileSize(selectedFile)}</span>
              </div>
              <div>
                <IconButton color="red" size="sm" onClick={() => {
                  setSelectedFile(null)
                }}>
                  <XCircleIcon height={20} />
                </IconButton>
              </div>
            </div>
              <div className="py-5">
                <Input variant="standard" label="Tên âm thanh" value={fileName} onChange={(e) => {
                  setFileName(e.target.value)
                }} />
              </div>
            </React.Fragment>
          }

        </DialogBody>
        <DialogFooter>
          {
            selectedFile && <Button
              variant="text"
              color="green"
              onClick={() => {
                setShowModal(false)
              }}
              className="mr-1"
            >
              <span>Thêm</span>
            </Button>
          }

          <Button
            variant="text"
            color="red"
            onClick={() => {
              setShowUploadModal(false);
              setFileName(null);
              setSelectedFile(null)
            }}
            className="mr-1"
          >
            <span>Đóng</span>
          </Button>
        </DialogFooter>
      </Dialog>

    </React.Fragment>
  )
}