import { useState } from 'react'
import { Tab } from '@headlessui/react'
import React from 'react'
import OthersSettings from './Others'
import AudioSettings from './Audio'
import ImageSettings from './Image'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Settings() {
  let [categories] = useState({
    "Others": <OthersSettings />,
    "Audio": <AudioSettings />,
    "Image": <ImageSettings />,
  })

  return (
    <div className="w-full px-4  ">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  'w-fit px-5 rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Object.values(categories).map((component, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                'rounded-lg p-3',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >
              {component}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
