import React from "react";

export default function ImageSettings() {
  return (
    <React.Fragment>
      <div className="grid grid-cols-4 gap-2">
        <div>
          <div className="rounded border shadow-lg p-5 text-center">
            <img src="https://www.material-tailwind.com/_next/image?url=%2Fimg%2Fblog.jpg&w=1920&q=75" />
          </div>
        </div>

        <div>
          <div className="rounded border shadow-lg p-5 text-center">
            <img src="https://www.material-tailwind.com/_next/image?url=%2Fimg%2Fblog.jpg&w=1920&q=75" />
          </div>
        </div>

        <div>
          <div className="rounded border shadow-lg p-5 text-center">
            <img src="https://www.material-tailwind.com/_next/image?url=%2Fimg%2Fblog.jpg&w=1920&q=75" />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}